import {
  Box,
  BoxProps,
  Link as ChakraLink,
  CloseButton,
  Divider,
  Drawer,
  DrawerContent,
  Flex,
  FlexProps,
  HStack,
  Icon,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { IconType } from 'react-icons';
import { FaCartPlus, FaFileInvoice, FaList } from 'react-icons/fa';
import {
  FiBox,
  FiBriefcase,
  FiChevronRight,
  FiDownload,
  FiFileText,
  FiGrid,
  FiPhoneCall,
  FiLink,
  FiSettings,
  FiShoppingCart,
  FiTag,
  FiUser,
} from 'react-icons/fi';
import { UserPermission, useAuth } from '@/hooks/AuthContext';
import { ClientPermission } from './CheckAuth';

interface LinkItemProps {
  name: string;
  icon?: IconType;
  href?: string;
  roles: string[];
  permissions?: UserPermission[];
  clientPermissions?: ClientPermission[];
  clientAccountTypes?: ('PROCURA' | 'SAAS')[];
  children?: Array<LinkItemProps>;
}

const LinkItems: Array<LinkItemProps> = [
  /*
    ADMIN
  */
  {
    name: 'Dashboard',
    icon: FiGrid,
    href: '/dashboard',
    roles: ['admin'],
    permissions: ['reports.sensitive'],
  },
  {
    name: 'Service Requests',
    icon: FiSettings,
    href: '/service-requests',
    roles: ['admin'],
  },
  {
    name: 'Admins',
    icon: FiUser,
    href: '/admins',
    roles: ['admin'],
    permissions: ['superadmin'],
  },
  {
    name: 'Organizations',
    icon: FiBriefcase,
    href: '/clients',
    roles: ['admin'],
  },
  {
    name: 'Vendors',
    icon: FiBox,
    href: '/vendors',
    roles: ['admin'],
  },
  { name: 'Orders', icon: FiTag, href: '/orders', roles: ['admin'] },
  {
    name: 'Shop Import',
    icon: FiDownload,
    href: '/shop-import',
    roles: ['admin'],
    permissions: ['shop.manage'],
  },
  {
    name: 'Quotation Maker',
    icon: FaFileInvoice,
    href: '/tools/quotation',
    roles: ['admin'],
    permissions: ['quotations.manage'],
  },
  {
    name: 'Client Leads',
    icon: FiPhoneCall,
    href: '/crm',
    roles: ['admin'],
    permissions: ['crm.all'],
  },
  {
    name: 'Vendor Leads',
    icon: FaList,
    href: '/vms',
    roles: ['admin'],
    permissions: ['vms.all'],
  },
  { name: 'Links', icon: FiLink, href: '/links', roles: ['admin'] },
  {
    name: 'Reports',
    icon: FiFileText,
    roles: ['admin'],
    permissions: ['reports.general', 'reports.sensitive'],
    children: [
      {
        name: 'General',
        href: '/reports',
        roles: ['admin'],
        permissions: ['reports.general', 'reports.sensitive'],
      },
      {
        name: 'Company',
        href: '/reports/company',
        roles: ['admin'],
        permissions: ['reports.general', 'reports.sensitive'],
      },
      {
        name: 'Products',
        href: '/reports/product',
        roles: ['admin'],
        permissions: ['reports.general', 'reports.sensitive'],
      },
    ],
  },

  /*
    CLIENT
  */
  {
    name: 'Dashboard',
    icon: FiGrid,
    href: '/dashboard',
    roles: ['client'],
    permissions: ['reports.sensitive'],
    clientPermissions: ['canCreateOrder', 'canViewAllAddresses'],
  },
  { name: 'My Profile', icon: FiUser, href: '/clients/me', roles: ['client'] },
  {
    name: 'My Products',
    icon: FiBox,
    href: '/clients/me/products',
    roles: ['client'],
  },
  {
    name: 'Organization',
    icon: FiBriefcase,
    href: '/clients/me/organization',
    roles: ['client'],
    clientPermissions: ['canCreateOrder', 'canViewAllAddresses'],
  },
  {
    name: 'Vendors',
    icon: FiBox,
    href: '/vendors',
    roles: ['client'],
    clientAccountTypes: ['SAAS'],
    clientPermissions: ['canCreateOrder', 'canViewAllAddresses'],
  },
  { name: 'Orders', icon: FiShoppingCart, href: '/orders', roles: ['client'] },
  {
    name: 'Order Requests',
    icon: FaCartPlus,
    href: '/order-requests',
    roles: ['client'],
  },
  {
    name: 'Invoices',
    icon: FaFileInvoice,
    href: '/invoices',
    roles: ['client'],
    clientPermissions: ['canViewAllAddresses'],
  },
  {
    name: 'Reports',
    icon: FiFileText,
    roles: ['client'],
    clientPermissions: ['canViewAllAddresses'],
    children: [
      {
        name: 'General',
        href: '/reports',
        roles: ['client'],
      },
      {
        name: 'Company',
        href: '/reports/company',
        roles: ['client'],
      },
      {
        name: 'Products',
        href: '/reports/product',
        roles: ['client'],
      },
    ],
  },
  /* 
    VENDORS
  */
  {
    name: 'Clients',
    icon: FiBriefcase,
    href: '/clients',
    roles: ['vendor'],
  },
  { name: 'Orders', icon: FiTag, href: '/orders', roles: ['vendor'] },
  // { name: 'Vendor RFQs', icon: FiInbox, href: '/requests', roles: ['admin'] },
  // { name: 'RFQs', icon: FiInbox, href: '/requests', roles: ['vendor'] },
  // { name: 'Client RFQs', icon: FiInbox, href: '/client-rfqs', roles: ['admin'] },
  // { name: 'Your RFQs', icon: FiInbox, href: '/client-rfqs', roles: ['client'] },
  // { name: 'Vendors', icon: FiCompass, href: '/vendors', roles: ['admin'] },
  // { name: 'Clients', icon: FiBriefcase, href: '/clients', roles: ['admin'] },
  // { name: 'Admins', icon: FiUser, href: '/admins', roles: ['admin'] },
  // { name: 'Categories', icon: FiStar, href: '/categories', roles: ['admin'] },
  // { name: 'Purchase Orders', icon: FiShoppingCart, href: '/orders', roles: ['admin', 'client', 'vendor'] },
  // { name: 'Invoices', icon: FiDollarSign, href: '/invoices', roles: ['admin', 'client', 'vendor'] },
  // { name: 'Shop Import', icon: FiShoppingBag, href: '/shop-import', roles: ['admin'] },
  /*
    VENDOR
  */
];

export default function Sidebar({ isOpen, onOpen, onClose }) {
  return (
    <>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        overflowY='auto'
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size='full'
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
    </>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { user } = useAuth();

  return (
    <Box
      transition='3s ease'
      bg='white'
      shadow='md'
      w={{ base: 'full', md: 60 }}
      pos='fixed'
      color='gray.600'
      pb='4'
      h='full'
      {...rest}
    >
      <Flex
        h='20'
        alignItems='center'
        mx='4'
        justifyContent='center'
        borderBottom='solid 1px'
        borderColor='gray.200'
      >
        <Box position='relative'>
          <Link href='/'>
            <Image
              alt='Procura Logo'
              height='20'
              py='5'
              px='4'
              objectFit='contain'
              objectPosition='center'
              src='/images/brand-logo.png'
            />
          </Link>
          {process.env.NEXT_PUBLIC_NODE_ENV != 'production' && (
            <Box
              position='absolute'
              fontSize='xs'
              fontWeight='bold'
              textAlign='right'
              w='full'
              marginTop='-8'
              pr='4'
            >
              DEV
            </Box>
          )}
        </Box>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      {LinkItems.filter(link => link.roles.includes(user.type))
        .filter(link => {
          if (user.type !== 'admin') return true;
          if (user.permissions.includes('superadmin')) return true;
          if (link.permissions) {
            if (
              !link.permissions.some(permission =>
                user.permissions.includes(permission),
              )
            )
              return false;
          }
          return true;
        })
        .filter(link => {
          if (user.type !== 'client') return true;
          if (!user.role) return false;

          const permissions = link.clientPermissions || [];
          for (const permission of permissions) {
            if (!user.role[permission]) return false;
          }

          if (
            link.clientAccountTypes &&
            !link.clientAccountTypes.includes(user.accountType)
          ) {
            return false;
          }

          return true;
        })
        .map(link => (
          <NavItem key={link.name} link={link} closeDrawer={onClose} />
        ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  link: LinkItemProps;
  level?: number;
  closeDrawer?: () => void;
}

const NavItem = ({
  link,
  closeDrawer = null,
  level = 0,
  ...rest
}: NavItemProps) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack spacing='0'>
      <ChakraLink
        as={link.href ? Link : 'button'}
        href={link.href}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
        onClick={e => {
          if (link.children && link.children.length > 0) {
            e.preventDefault();
            setIsOpen(current => !current);
          } else if (closeDrawer) closeDrawer();
        }}
        w='full'
      >
        <Flex
          align='center'
          px='6'
          py='3'
          mt='1'
          borderRadius='lg'
          role='group'
          fontSize='sm'
          color='gray.500'
          fontWeight='medium'
          transition='all 0.25s'
          cursor='pointer'
          position='relative'
          {...(router.pathname === link.href ? { color: 'orange.500' } : {})}
          _after={{
            content: '""',
            w: '4px',
            h: 'full',
            bg: 'orange.500',
            position: 'absolute',
            top: 0,
            left: 0,
            transform:
              router.pathname === link.href ? 'scaleY(1)' : 'scaleY(0)',
            transformOrigin: 'center right',
            transition: 'transform 0.25s ease-in-out',
          }}
          outline='solid 1px'
          outlineColor='transparent'
          _hover={{
            color: 'orange.500',
            // bg: 'orange.50',
          }}
          {...rest}
        >
          <HStack spacing={0}>
            {Array.from(Array(level).keys()).map(i => (
              <Box key={i} w='6' opacity={0} />
            ))}
          </HStack>

          {link.children && link.children.length > 0 && (
            <HStack w='6' alignItems='center'>
              <Text
                transition='all 0.25s'
                transform={isOpen ? 'rotate(90deg)' : ''}
              >
                <FiChevronRight />
              </Text>
            </HStack>
          )}

          {link.icon && <Icon mr='4' fontSize='xl' as={link.icon} />}
          <Text>{link.name}</Text>
        </Flex>
      </ChakraLink>

      <Stack
        maxH={isOpen ? '256px' : '0px'}
        transition='all 0.25s'
        overflow='hidden'
        spacing='0'
        pb='2px'
      >
        {link.children &&
          link.children.length > 0 &&
          link.children.map(child => (
            <NavItem
              key={child.name}
              link={child}
              closeDrawer={closeDrawer}
              level={level + 1}
            />
          ))}
        {isOpen && <Divider />}
      </Stack>
    </Stack>
  );
};
